import React, { useEffect } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactInfo from '@widgets/ContactInfo'
import Poster from '@widgets/Poster'
import AdHorizontal from '@widgets/AdHorizontal'
import ContactFormPost from '@widgets/ContactFormPost'
import { Card as CardComponent } from 'theme-ui'
// import BlockContent from '@sanity/block-content-to-react'
import { PostBody } from '@widgets/Post'
import { isLoggined } from '@helpers-blog'
import './Live.css'

const Live = () => {
  useEffect(() => {
    if (!isLoggined()) {
      navigate('/login')
    }
  })
  const data = useStaticQuery(liveQuery)
  const live = data.allSanityLive.nodes[0]
  const specific = data.allArticle.edges[0].node
  console.log(specific)

  // const serializer = {
  //   types: {
  //     block(props) {
  //       switch (props.node.style) {
  //         case 'h1':
  //           return (
  //             <h1
  //               style={{
  //                 color: 'rgb(45, 55, 72)',
  //                 fontSize: '48px',
  //                 fontWeight: '900',
  //                 fontFamily: 'Inter,sans-serif',
  //                 lineHeight: '50px'
  //               }}
  //             >
  //               {props.children}
  //             </h1>
  //           )
  //         default:
  //           return (
  //             <p style={{ color: 'rgb(45, 55, 72)', fontSize: '18x' }}>
  //               {props.children}
  //             </p>
  //           )
  //       }
  //     }
  //   }
  // }

  return (
    <>
      <Seo title='ACS Live' />
      <Divider />
      <Stack>
        <PageTitle header={live.title} subheader={live.subTitle} />
      </Stack>
      <Stack>
        <div className='live-container'>
          <Main>
            <Divider space={2} />
            <AdHorizontal no={1} />{' '}
            {/* you can use only 1 ~ 5, if you omit no={1~5} AdHorizontal process the first banner for default */}
            {/* <AdHorizontal no={2} />
          <AdHorizontal no={3} />
          <AdHorizontal no={4} />
        <AdHorizontal no={5} /> */}
            <Divider space={2} />
            <CardComponent variant='paper'>
              {/* <iframe
                title='live video'
                width='100%'
                height='400'
                src={live.videoLink}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              ></iframe> 
              <Divider space={2} /> */}
              {/* <BlockContent
                blocks={live.description}
                serializers={serializer}
              /> */}
              <PostBody {...specific} />
            </CardComponent>
            <Divider space={2} />
            <h2>Sorunuzu İletin</h2>
            {/* <ContactFormPost /> */}
            <Divider space={2} />
            <CardComponent variant='paper'>
              <iframe src="https://app.sli.do/event/qgZv8BTvS6aFGF9XnPL19o/questions" 
              width="100%" height="800px" frameborder="0" allowfullscreen="true"></iframe>
              <Divider space={2}/>
              </CardComponent> 
              <Divider space={2} />
              <CardComponent variant='paper'>
                Perkütan koroner girişimlerde yeni bir antiplatelet tedavi olan Kangrelor'u tüm detayları ile tartışacağımız canlı webinarımıza hoşgeldiniz. ACS stüdyosunda Prof. Dr. Hakan Karpuz'a (İ.Ü. Cerrahpaşa Tıp Fakültesi) ve konuşmacılarımız Prof. Dr. Bilgehan Karadağ (İ.Ü. Cerrahpaşa Tıp Fakültesi) ve Prof. Dr. Alp Burak Çatakoğlu'na (Liv Hospital Ulus) sorularınızı canlı olarak sorabileceksiniz. 
                <p><b>19:00 Açılış</b> (Prof. Dr. Hakan Karpuz)</p> 
                <p><b>19:05 PKG'de Antiplatelet Tedavide Yenilikler</b> (Prof. Dr. Bilgehan Karadağ)</p> 
                <p><b>19:40 PKG'de Kangrelor: Olgu Sunumu</b> (Prof. Dr. Alp Burak Çatakoğlu)</p> 
                <p><b>20:00 Tartışma ve Kapanış</b></p>
              </CardComponent>
          </Main>
          <Sidebar>
            <Divider space={2} />
            <Poster no={1} />
            {/* <Poster no={1} />
            <Poster no={2} />
          <Poster no={3} />
          <Poster no={4} />
          <Poster no={5} /> */}
            <Divider space={2} />
            <Poster no={2} />
            <Divider space={3} />
            <ContactInfo />
          </Sidebar>
        </div>
      </Stack>
    </>
  )
}

const liveQuery = graphql`
  query liveQuery {
    allSanityLive {
      nodes {
        title
        subTitle
        videoLink
      }
    }
    allArticle(filter: { title: { eq: "Live Embed" } }) {
      edges {
        node {
          body
        }
      }
    }
  }
`
export default Live
